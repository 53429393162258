import React, { Component } from 'react';
import classnames from 'classnames';
import ellipsis from 'text-ellipsis';

import OptionsEditor from './OptionsEditor';

const defaultSlideTitles = {
  mission: 'セッションの目的',
  schedule: 'セッションの進め方',
  future_session: 'Future Session',
  future_thinking: '未来思考',
  ground_rules: 'グランドルール',
  request: 'お願い',
};


export default class PresentationEditorColumn extends Component {
  componentDidMount() {
    OF.renderRichText();
  }
  renderSlideBody() {
    const { column: { key }, mission, programTimes, steps } = this.props;
    return ({
      mission: (
        <div>
          {ellipsis(mission || '', 90)}
        </div>
      ),
      schedule: (
        <div>
          {
            steps.slice(0, 2).map(({ title }, i) => {
              return (
                <div key={i}>
                  <span>{i+1}. {programTimes[i]}</span>
                  <span className="ml15">
                    <span>{title}</span>
                  </span>
                </div>
              )
            })
          }
          <div>...</div>
        </div>
      ),
      future_session: (
        <div>
          未来のステークホルダーを招き入れ、創造的な対話を通して、
          <br />
          未来に向けての「新たな関係性」と「新たなアイデア」を生み出し、
          <br />
          ステークホルダー同士が「協力して行動できる」状況を生み出すための場
        </div>
      ),
      future_thinking: (
        <div>
          <div>
            <div>
              バックキャスティング（重大な変化を予期し、備える）
            </div>
            <div>
              極端な未来が「来るもの」と仮定して、「そうなったとき、私たちはどうしているだろうか？」と考える（できない理由ではなく、できたときのことを考える）
            </div>
          </div>
          <div>
            <div>
              フォアキャスティング（確率の高い未来を予測する）
            </div>
            <div>
              予測をしようとすると、確からしさを求めるために、現状の「変わらない理由」に絡めとられてしまう
            </div>
          </div>
        </div>
      ),
      ground_rules: (
        <div>
          <div>
            一人ひとりの想いを大切にする
          </div>
          <div>
            お互いの違いや多様性から学び、アイデアを生み出す源泉とする
          </div>
          <div>
            参加者全員で発言機会を提供しあい、よい関係性をつくりあげる
          </div>
          <div>
            いつもの主張をただ話すのではなく、その場で感じたことを大切にする
          </div>
          <div>
            今日この場で一緒になった縁を大切に、アクションを支援し合う
          </div>
        </div>
      ),
      request: (
        <div>
          <div>
            対話の時間が終了したら、ファシリテーターが手をあげます
          </div>
          <div>
            それに気付いた人は、手をあげて、話をそこでやめてください
          </div>
          <div>
            全員が手をあげて、会場全体が静かになるまで、手をあげていてください
          </div>
        </div>
      ),
    })[key];
  }
  render () {
    const { imagePostPath, className, column: { key, isPreset, isBasic, body = '', hidden }, onChange, onClickUp, onClickDown, onClickRemove, isClickableUp, isClickableDown, isClickableRemove } = this.props;
    return (
      <div className={classnames('presentation-editor-column', className)}>
        <div className="form-group mb5" style={{ display: 'flex' }}>
          <div>
            {
              !isBasic && (
                <div className="text-left">
                  <input
                    id={`${key}_hidden`}
                    type="checkbox"
                    checked={hidden}
                    onChange={({ target: { checked } }) => onChange('hidden', checked) }
                    className="mr5"
                  />
                  <label htmlFor={`${key}_hidden`} className="cursor-pointer">非表示</label>
                </div>
              )
            }
            {
              (isBasic || isPreset) && (
                <div className="text-grey">
                  <div>
                    {
                      isPreset && <span>プリセットスライド </span>
                    }
                    「{defaultSlideTitles[key]}」
                  </div>
                </div>
              )
            }
          </div>
          <div className="text-right" style={{ flexGrow: 1 }}>
            <button type="button" className="btn btn-xs btn-default" disabled={!isClickableUp} onClick={onClickUp} data-toggle={isClickableUp && 'tooltip'} data-title="上に移動する">
              <i className="fa fa-arrow-up" />
            </button>
            <button type="button" className="btn btn-xs btn-default ml5" disabled={!isClickableDown} onClick={onClickDown} data-toggle={isClickableDown && 'tooltip'} data-title="下に移動する">
              <i className="fa fa-arrow-down" />
            </button>
            <button type="button" className="btn btn-xs btn-danger ml5" disabled={!isClickableRemove} onClick={onClickRemove} data-toggle={isClickableRemove && 'tooltip'} data-title="削除する">
              <i className="fa fa-close" />
            </button>
          </div>
        </div>
        {
          isBasic || isPreset ? (
            <div className="text-grey">
              {
                !hidden && (
                  <div className="small mt10">
                    {this.renderSlideBody()}
                  </div>
                )
              }
            </div>
          ) : (
            <div className="form-group">
              <div style={{ cursor: 'initial' }}>
                <textarea
                  ref={_ => this.textarea = _}
                  id={key}
                  className="form-control summernote-thin"
                  value={body}
                  data-image-post-path={imagePostPath}
                  required
                />
              </div>
            </div>
          )
        }
      </div>
    );
  }
}

