import Dexie from 'dexie'

export const initializeStore = (dbName, storeName) => {
  const db = new Dexie(dbName)
  db.version(1).stores({
    [storeName]: 'id'
  })
  return db
}

export const updateStore = async (db, storeName, id, key, value) => {
  const data = await db[storeName].get(id) || {}
  data[key] = value
  db[storeName].put({ id, ...data })
}

export const checkData = (data, formData) => {
  if (data) {
    return formData.some((item) => {
      const key = `session_${item.name.replace('session[', '').replace(']', '')}`
      return data[key] !== undefined && data[key] !== item.value
    })
  }
  return false
}
