import React, { Component } from 'react';
import classnames from 'classnames';

export default class OptionsEditor extends Component {
  remove(index) {
    return () => {
      const { options, onChange } = this.props;
      onChange([
        ...options.slice(0, index),
        ...options.slice(index + 1)
      ]);
    }
  }
  add() {
    const { options, onChange } = this.props;
    onChange([...options, '']);
  }
  updateOption(index) {
    return ({ target: { value } }) => {
      const { options, onChange } = this.props;
      onChange([
        ...options.slice(0, index),
        value,
        ...options.slice(index + 1),
      ]);
    }
  }
  render () {
    const { options, className } = this.props;
    return (
      <div className={classnames('OptionsEditor', className)}>
        <div className="form-inline">
          {
            options.map((option, index) => {
              return (
                <div key={index} className="form-group m-r-2 m-b-1 option">
                  <input
                    className="form-control"
                    type="text"
                    value={option}
                    onChange={this.updateOption(index)}
                    required
                  />
                  {
                    options.length > 1 &&
                      <div className="btn btn-secondary text-danger btn-xs" onClick={this.remove(index)}><i className="fa fa-close"></i></div>
                  }
                </div>
              );
            })
          }
          <div className="form-group m-r-2 m-b-1">
            <div className="btn btn-default option-adder" onClick={this.add.bind(this)}><i className="fa fa-fw fa-plus"></i></div>
          </div>
        </div>
      </div>
    );
  }
}
