import React, { Component } from 'react';
import Slider from 'react-slick';
import Linkify from 'react-linkify';

import './Slide.css';

export default class Slide extends Component {
  constructor() {
    super();
    this.state = {
      currentIndex: 0,
    };
  }
  render() {
    const { images, title_content: titleContent } = this.props;
    const settings = {
      dots: true,
      fade: true,
      infinite: true,
      speed: 1000,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      adaptiveHeight: true,
      afterChange: currentIndex => this.setState({ currentIndex }),
    };
    const currentImage = images[this.state.currentIndex];
    return (
      <div className="slide">
        <Slider {...settings}>
          {
            images.map(({ id, image: { url } }) => {
              return (
                <div key={id}>
                  <img src={url} />
                </div>
              );
            })
          }
        </Slider>
        {
          (titleContent || currentImage.caption) && (
            <div className="slide-overlay">
              <div className="text-center title" dangerouslySetInnerHTML={{ __html: titleContent }}></div>
              <Linkify properties={{ target: '_blank' }}>
                {
                  currentImage.caption && (
                    <div className="slide-caption">
                      <span>{currentImage.caption}</span>
                    </div>
                  )
                }
              </Linkify>
            </div>
          )
        }
      </div>
    );
  }
};
