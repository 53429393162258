import Dexie from 'dexie'
import { debounce } from 'lodash'

const $steps = $('.js-steps')
const id = $steps.data('session-id')
const DEBOUNCE_TIME = 500
const db = new Dexie('stepStore')

db.version(1).stores({
  steps: 'id'
})

// restoreStepの時に追加された要素に対して適応しないとうまく動かない
// TODO v2/designs.js.coffeeと同じコードなのでDRYにしたい
const applySortable = function () {
  $('.programs-container').sortable({
    handle: '.drag-bar',
    axis: 'y',
    connectWith: '.programs-container',
    placeholder: 'ui-state-highlight',
    forcePlaceholderSize: true,
  })

  $('.methods-container').sortable({
    handle: '.drag-bar',
    axis: 'y',
    connectWith: '.methods-container',
    placeholder: 'ui-state-highlight',
    forcePlaceholderSize: true,
  })
}
// TODO v2/designs.js.coffeeと同じコードなのでDRYにしたい
const renewStepNumber = function () {
  let number = 1
  $(".programs-container .program-step-number").each(function () {
    $(this).text(number)
    number += 1
  })
}
// TODO v2/designs.js.coffeeと同じコードなのでDRYにしたい
const formatDate = function (date, format) {
  if (!format) {
    format = 'hh:mm'
  }
  format = format.replace(/YYYY/g, date.getFullYear())
  format = format.replace(/MM/g, ('0' + (date.getMonth() + 1)).slice(-2))
  format = format.replace(/DD/g, ('0' + date.getDate()).slice(-2))
  format = format.replace(/hh/g, ('0' + (date.getUTCHours() + 9)).slice(-2))
  format = format.replace(/mm/g, ('0' + date.getMinutes()).slice(-2))
  format = format.replace(/ss/g, ('0' + date.getSeconds()).slice(-2))
  if (format.match(/S/g)) {
    const milliSeconds = ('00' + date.getMilliseconds()).slice(-3)
    const length = format.match(/S/g).length
    for (let i = 0; i < length; i++) {
      format = format.replace(/S/, milliSeconds.substring(i, i + 1))
    }
  }
  return format
}
// TODO v2/designs.js.coffeeと同じコードなのでDRYにしたい
const calcProgramTimeFromTo = function () {
  const timeStr = $('.session-schedule').data().startAt
  const startAt = new Date(Date.parse(timeStr))
  if (isNaN(startAt)) return
  let currentStartAt = startAt
  let currentEndAt = startAt
  $(".programs-container .program-container").each(function () {
    const $programContainer = $(this)
    $programContainer.find('.method-during-time-field').each(function () {
      const $timeField = $(this)
      const minuteStr = $timeField.val()
      const minute = parseInt(minuteStr) || 0
      currentEndAt = new Date(currentEndAt.getTime() + minute * 60000)
    })
    const fromToText = formatDate(currentStartAt) + "-" + formatDate(currentEndAt)
    $programContainer.find('.js-program-time-from-to').text(fromToText)
    currentStartAt = currentEndAt
  })
}

const buildStep = () => {
  // 0~76番目までは実際の値と関係ない（複製用のフォーム）
  const formData = $('.js-program-form').serializeArray().slice(77)
  const result = {}
  let currentStep = null
  let currentMethod = null
  let stepCount = 0
  let methodCount = 0

  formData.forEach(function (item) {
    if (item.name === '[title]') {
      stepCount++
      methodCount = 0
      currentStep = { title: item.value }
      result['step' + stepCount] = currentStep
    } else if (currentStep) {
      if (item.name === '[methods][][key]') {
        methodCount++
        currentMethod = {}
        currentStep['method' + methodCount] = currentMethod
      } else if (currentMethod) {
        const key = item.name.replace('[methods][]', '').replace(/[\[\]]/g, '')
        if (key === 'memo') {
          currentMethod[key] = $(`.js-method${methodCount}`).find('.js-method-memo .note-editable').html()
        } else {
          currentMethod[key] = item.value
        }
      }
    }
  })

  return result
}

const updateStore = async () => {
  const data = buildStep()
  await db.steps.put({ id: id, ...data })
}

const updateStepStore = () => {
  $(document).on('change', '.js-steps input', debounce(async () => {
    await updateStore()
  }, DEBOUNCE_TIME))

  $('#add-method-modal').on('hidden.bs.modal', debounce(async () => {
    await updateStore()
  }, DEBOUNCE_TIME))

  $(document).on('DOMSubtreeModified propertychange', '.js-steps .note-editable.panel-body', debounce(async () => {
    await updateStore()
  }, DEBOUNCE_TIME))

  const observer = new MutationObserver((mutations) => {
    mutations.forEach(async (mutation) => {
      await updateStore()
    })
  })
  const config = { childList: true, characterData: true, subtree: true }
  if ($steps.find('.program-container').length > 0) {
    observer.observe($steps.get(0), config)
    observer.observe($('.js-methods').get(0), config)
  }
}

const restoreStepStore = async () => {
  const data = await db.steps.get(id)
  const formData = { id: id, ...buildStep() }
  const isNotEqual = JSON.stringify(data) !== JSON.stringify(formData)
  if (data && isNotEqual && confirm('未保存の内容があります。復元しますか？')) {
    for (const step in data) {
      if (step.includes('step')) {
        let $step = $(`.js-${step}`)
        // ステップのフォームがなければ作成
        if ($step.length === 0) {
          const $target = $('.js-complement-program > .program-container').clone()
          $('.programs-container').append($target)
          $step = $('.js-new-step').last()
        }
        for (const key in data[step]) {
          if (key === 'title') {
            $step.find('.js-step-title').val(data[step][key])
          } else if (key.includes('method')) {
            let $method = $step.find(`.js-${key}`)
            // メソッドのフォームがなければ作成
            if ($method.length === 0) {
              const $target = $('.complement-methods .blank.method-container').clone()
              $step.find('.js-methods').append($target)
              $method = $step.find('.js-method').last()
            }
            for (const methodKey in data[step][key]) {
              const val = data[step][key][methodKey]
              if (methodKey.includes('description')) {
                $method.find('.note-editable.panel-body').html(val)
              } else if (methodKey.includes('memo')) {
                $method.find(`.js-method-${methodKey} .note-editable`).html(val)
              } else {
                $method.find(`.js-method-${methodKey}`).val(val)
              }
            }
          }
        }
      }
    }
    applySortable()
    renewStepNumber()
    calcProgramTimeFromTo()
  } else if (data) {
    await db.steps.delete(id)
  }
}

const deleteStepStore = () => {
  $('.js-submit-button').click(() => {
    db.steps.delete(id)
  })
}

export const handleStepStore = async () => {
  if ($steps.length > 0) {
    await restoreStepStore()
    updateStepStore()
    deleteStepStore()
  }
}
