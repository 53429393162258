import bytes from 'bytes';

export function customizeFileInputs() {
  const inputs = Array.from(document.querySelectorAll('input[type="file"]'));
  inputs.forEach((input) => {
    input.addEventListener('change', ({ target: { files: [file], parentNode }, target }) => {
      if (!parentNode) return;
      const prevFileSizeContainer = parentNode.querySelector('.file-size-container');
      prevFileSizeContainer && prevFileSizeContainer.remove();
      const fileSizeContainer = document.createElement('div');
      fileSizeContainer.setAttribute('class', 'file-size-container text-grey small');
      fileSizeContainer.textContent = `(${bytes(file.size)})`;
      parentNode.insertBefore(fileSizeContainer, target.nextSibling);
    });
  });
}

export function formatFileSizes() {
  Array.from(document.querySelectorAll('.js-file-size')).forEach((elm) => {
    const num = parseFloat(elm.textContent, 10);
    if(!(num >= 0)) return;
    elm.textContent = `(${bytes(num)})`;
    elm.style.visibility = 'visible';
  });
}
