import { WebsocketProvider } from '@y-rb/actioncable'
import { Doc } from 'yjs'
import { createConsumer } from '@rails/actioncable'
import { QuillBinding } from 'y-quill'
import Quill from 'quill'
import QuillCursors from 'quill-cursors'
import ImageResize from 'quill-image-resize'
import { debounce } from 'lodash'

Quill.register({
  'modules/cursors': QuillCursors,
  'modules/imageResize': ImageResize,
})

const DEBOUNCE_TIME = 500
const $target = $('.js-session-memo-editor-contents')
const sleep = delay => new Promise(resolve => setTimeout(resolve, delay))

const updateSessionMemo = debounce((content) => {
  const url = $target.data('request-url')
  $.ajax({
    type: 'PATCH',
    url,
    data: {
      content
    },
  })
}, DEBOUNCE_TIME)

const insertImage = (file) => {
  const data = new FormData()
  data.append('user_image[image]', file)
  return $.ajax({
    type: 'POST',
    url: '/user_images',
    dataType: 'json',
    data,
    cache: false,
    contentType: false,
    processData: false
  })
}

const imageHandler = (quill) => {
  const $target = $('.js-session-editor-image-button')
  const $input = $('.js-session-editor-image-file-input')
  $target.addEventListener('click', () => {
    const files = $input.prop('files')
    if (files) {
      insertImage(files[0])
          .done((response) => {
            quill.insertEmbed(quill.getSelection().index, 'image', response.user_image.image_url)
            $('.js-session-editor-image-modal').modal('hide')
          })
    }
  })
}

class SessionMemoEditor {
  constructor(element) {
    this.element = element
    this.connect()
  }

  connect() {
    const consumer = createConsumer()
    const document = new Doc()
    const type = document.getText('quill')
    const id = $target.data('session-id')
    const provider = new WebsocketProvider(
        document,
        consumer,
        'SyncChannel',
        {
          id
        }
    )

    const quill = new Quill(this.element, {
      modules: {
        cursors: true,
        toolbar: [
          [{ header: [1, 2, false] }],
          ['bold', 'italic', 'underline'],
          [{ 'list': 'ordered' }, { 'list': 'bullet' }],
          ['image', 'summarize']
        ],
        history: {
          userOnly: true
        },
        imageResize: {
          displaySize: true
        }
      },
      placeholder: 'Start collaborating...',
      theme: 'snow'
    })

    new QuillBinding(type, quill, provider.awareness)

    provider.awareness.on('update', () => {
      updateSessionMemo(quill.root.innerHTML)
    })

    provider.awareness.setLocalStateField('user', { name: $target.data('user-name') })

    quill.getModule('toolbar').addHandler('image', () => $('.js-session-editor-image-modal').modal('show'))
    imageHandler(quill)
  }
}

const applyDefaultText = async () => {
  // NOTE: websocketの通信が終わって反映されるまでまつ
  await sleep(1000)
  if ($target.find('.ql-editor').text().trim().length === 0) {
    $target.find('.ql-editor').html($target.data('default-text'))
  }
}

const postData = (content, url) => {
  $.ajax({
    type: 'POST',
    url,
    data: { content }
  })
}

const handleClick = ($button, url, $target) => {
  const selectedText = getSelection().toString()
  const allText = $target.find('.ql-editor').text()
  const $dropdown = $('.js-session-memo-dropdown')

  if (selectedText.length > 0 && allText.replace(/\r?\n/g, '').includes(selectedText.replace(/\r?\n/g, ''))) {
    $button.addClass('is-summarize')
    postData(selectedText, url)
  } else if (selectedText.length === 0) {
    $button.addClass('is-summarize')
    postData($target.find('.ql-editor').html().replace(/<p><br><\/p>/g, '').replace(/<img[^>]*>/g, ''), url)
  } else {
    alert('当日メモ内を選択してください')
  }
}

const aiSummarize = () => {
  const $target = $('.js-session-memo-editor')
  const $dropdown = $('.js-session-memo-dropdown')
  let $summarizedButton = $target.find('.ql-summarize')

  if ($dropdown?.length > 0) {
    $summarizedButton.remove()
    $dropdown.removeClass('hidden')
    $target.find('.ql-formats:last-child').append($dropdown)
    $summarizedButton = $dropdown.find('.js-session-memo-dropdown-button')
  }

  $summarizedButton.click(function () {
    const url = $(this).data('ai-summarize-url') || $('.js-session-memo-editor-contents').data('ai-summarize-url')
    handleClick($(this), url, $target)
  })
}

export const sessionMemoEditor = async () => {
  if ($target.length > 0) {
    new SessionMemoEditor($target[0])
    await applyDefaultText()
    aiSummarize()
  }
}
