import $ from 'jquery';
import { sumBy } from 'lodash';
import numeral from 'numeral';

export const setTimeCounter = () => {
  const { startAt, endAt } = $('.js-session-schedule').data() || {};
  if(!startAt) return;
  const totalMinutes = sumBy($('.program-step .method-during-time-field'), _ => parseInt(_.value, 10) || 0);
  const scheduleMinutes = (new Date(endAt) - new Date(startAt)) / 60 / 1000;
  const diff = totalMinutes - scheduleMinutes;
  const attr = diff > 0 ? ' class="text-danger"' : '';
  const icon = diff > 0 ? '<i class="fa fa-exclamation-triangle pr5" style="color: #ffc107" />' : '';
  $('.js-time-counter').html(`<div ${attr}>${icon}${totalMinutes}分 (${numeral(diff).format('+0')}分)</div>`);
};

window.setTimeCounter = setTimeCounter;

export const initTimeCounter = () => {
  setTimeCounter();
  $(document).on('change', '.js-time-counter-updater', setTimeCounter);
};
