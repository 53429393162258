import React from 'react';
import { render } from 'react-dom';
import 'slick-carousel/slick/slick.css';
import "slick-carousel/slick/slick-theme.css";

import { customizeFileInputs, formatFileSizes } from './fileSize';
import Slide from './components/Slide';
import PresentationEditor from './components/PresentationEditor';
import CompanySlide from './components/CompanySlide';
import './summernote';
import { initTimeCounter } from './views/init_time_counter';
import { handleSessionStore } from './views/handle_session_store';
import { handleReportStore } from './views/handle_report_store';
import { handleStepStore } from './views/handle_step_store';
import { sessionMemoEditor } from './views/session_memo_editor';

const components = [
  {
    selector: '#slide-container',
    Component: Slide,
  },
  {
    selector: '#company-slide-container',
    Component: CompanySlide,
  },
  {
    selector: '#presentation-editor-container',
    Component: PresentationEditor
  },
];

document.addEventListener('DOMContentLoaded', async () => {
  window.OF.formatFileSizes = formatFileSizes;
  components.forEach(({ selector, Component }) => {
    const containers = Array.from(document.querySelectorAll(selector));
    containers.forEach((container) => {
      render(<Component {...JSON.parse(container.dataset.props || null)} />, container);
    });
  });
  customizeFileInputs();
  formatFileSizes();
  initTimeCounter();
  await handleSessionStore();
  await handleReportStore();
  await handleStepStore();
  await sessionMemoEditor();
});
