import { initializeStore, updateStore, checkData } from '../util/store_functions'

const storeName = 'sessions'
const db = initializeStore('sessionStore', storeName)
const $sessionForm = $('.js-session-form')
const id = $sessionForm.data('session-id')

export const updateSessionStore = () => {
  $sessionForm.find('input, select').on('change', async function () {
    const key = $(this).attr('id')
    let value
    if ($(this).attr('type') === 'checkbox' || $(this).attr('type') === 'radio') {
      value = $(this).prop('checked')
    } else {
      value = $(this).val()
    }
    await updateStore(db, storeName, id, key, value)
  })

  $sessionForm.find('.note-editable.panel-body').on('DOMSubtreeModified propertychange', async function () {
    const key = $(this).parents('.js-summernote').children('.js-summernote-textarea').attr('id')
    const value = $(this).context.innerHTML
    await updateStore(db, storeName, id, key, value)
  })
}

export const restoreSessionStore = async () => {
  const data = await db[storeName].get(id)
  const formData = $sessionForm.serializeArray()
  if (checkData(data, formData) && confirm('未保存の内容があります。復元しますか？')) {
    for (const key in data) {
      const $target = $(`#${key}`)
      if ($target.attr('type') === 'checkbox' || $target.attr('type') === 'radio') {
        $target.prop('checked', data[key])
      } else if (['session_mission', 'session_participation', 'session_description', 'session_memo', 'session_information_note', 'session_organizer'].includes(key)) {
        $target.next().find('.note-editable.panel-body').html(data[key])
      } else {
        $target.val(data[key])
      }
    }
  } else {
    db[storeName].delete(id)
  }
}

export const deleteSessionStore = () => {
  $('.js-submit-button').click(() => {
    db[storeName].delete(id)
  })
}

export const handleSessionStore = async () => {
  if ($sessionForm.length > 0) {
    await restoreSessionStore()
    updateSessionStore()
    deleteSessionStore()
  }
}
