import { initializeStore, updateStore, checkData } from '../util/store_functions'

const storeName = 'reports'
const db = initializeStore('reportStore', storeName)
const $reportForm = $('.js-report-form')
const id = $reportForm.data('session-id')

export const updateReportStore = () => {
  $reportForm.find('textarea').on('change', async function () {
    const key = $(this).attr('id')
    const value = $(this).val()
    await updateStore(db, storeName, id, key, value)
  })

  $reportForm.find('.note-editable.panel-body').on('DOMSubtreeModified propertychange', async function () {
    const key = 'session_reports'
    const value = $(this).context.innerHTML
    await updateStore(db, storeName, id, key, value)
  })
}

export const restoreReportStore = async () => {
  const data = await db[storeName].get(id)
  const formData = $reportForm.serializeArray()
  if (checkData(data, formData) && confirm('未保存の内容があります。復元しますか？')) {
    for (const key in data) {
      if (key === 'session_reports') {
        $reportForm.find('.note-editable.panel-body').html(data[key])
      }
      const $target = $(`#${key}`)
      $target.val(data[key])
    }
  } else {
    db[storeName].delete(id)
  }
}

export const deleteReportStore = () => {
  $('.js-submit-button').click(() => {
    db[storeName].delete(id)
  })
}

export const handleReportStore = async () => {
  if ($reportForm.length > 0) {
    await restoreReportStore()
    await updateReportStore()
    deleteReportStore()
  }
}
