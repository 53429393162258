import stripTags from 'striptags';
import { position } from 'caret-pos';

const allowedTags = ['br', 'p', 'blockquote', 'pre', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'b', 'i', 'u', 'ul', 'li', 'ol', 'a', 'img', 'iframe'];

window.summernotePasteCallback = ({ target }) => {
  const editableElm = target.closest('.note-editable');
  setTimeout(() => {
    const lastPosition = position(editableElm);
    const { innerHTML: html } = editableElm;
    const dummy = document.createElement('div');
    dummy.innerHTML = stripTags(html, allowedTags);
    Array.from(dummy.querySelectorAll('[style]')).forEach((elm) => {
      const style = elm.getAttribute('style')
        .split(';')
        .filter(_ => _)
        .filter(_ => ['text-align', 'width', 'height', 'float'].includes(_.match(/\s*([\w-]+):/)[1]))
        .join(';');
      elm.setAttribute('style', style);
    });
    editableElm.innerHTML = dummy.innerHTML;
    setTimeout(() => position(editableElm, lastPosition.pos));
  });
};
