import React, { Component } from 'react';
import Slider from 'react-slick';

import './CompanySlide.css';

export default class CompanySlide extends Component {
  constructor() {
    super();
    this.state = {
      currentIndex: 0,
    };
  }
  render() {
    const { images, title_content: titleContent } = this.props;
    const { currentIndex } = this.state;
    const settings = {
      dots: true,
      fade: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      adaptiveHeight: true,
      autoplay: true,
      autoplaySpeed: 5000,
      afterChange: currentIndex => this.setState({ currentIndex }),
    };
    const { href } = images[currentIndex];
    return (
      <div className="company-slide">
        <Slider {...settings}>
          {
            images.map(({ id, image: { url } }) => {
              return href ? (
                <span key={id} className="company-slide-image">
                  <a target="_blank" href={href} style={{ backgroundImage: `url(${url})`, backgroundSize: 'cover', backgroundPosition: '50%', height: '100%', display: 'block' }} />
                </span>
              ) : (
                <span key={id} className="company-slide-image">
                  <div style={{ backgroundImage: `url(${url})`, backgroundSize: 'cover', backgroundPosition: '50%', height: '100%' }}></div>
                </span>
              );
            })
          }
        </Slider>
      </div>
    );
  }
};
