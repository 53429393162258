import React, { Component } from 'react';
import { SortableContainer, SortableElement, arrayMove } from 'react-sortable-hoc';
import classnames from 'classnames';

import PresentationEditorColumn from './PresentationEditorColumn';
import parentNodes from '../util/parentNodes';

const presentationEditorColumnRefs = {};
const { entries } = Object;

const ColumnRows = SortableContainer(({ imagePostPath, columns, onChange, onClickUp, onClickDown, onClickRemove, mission, programTimes, steps }) => {
  return (
    <div className="column-rows">
      {
        columns.map((column, index) => {
          return (
            <ColumnRow
              key={column.key}
              {...{ columns, column, index, imagePostPath }}
              sortIndex={index}
              onChange={onChange(index)}
              onClickUp={onClickUp(index)}
              onClickDown={onClickDown(index)}
              onClickRemove={onClickRemove(index)}
              mission={mission}
              programTimes={programTimes}
              steps={steps}
            />
          );
        })
      }
    </div>
  );
});

const ColumnRow = SortableElement(({ imagePostPath, columns, column, onChange, onClickUp, onClickDown, onClickRemove, sortIndex: index, mission, programTimes, steps }) => {
  return (
    <div className="column-row m-b-3">
      <div className="panel panel-default" style={{ backgroundColor: column.hidden ? '#eee' : 'white' }}>
        <div className="panel-body">
          <PresentationEditorColumn
            ref={_ => presentationEditorColumnRefs[index] = _}
            {...{ imagePostPath, column, index, onChange, onClickUp, onClickDown, onClickRemove, isClickableUp: index > 0, isClickableDown: (index + 1) < columns.length, isClickableRemove: !(column.isPreset || column.isBasic), mission, programTimes, steps }} />
        </div>
      </div>
    </div>
  );
});

export default class PresentationEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      theme: props.theme,
      transition: props.transition,
      showPresetSlides: props.show_preset_slides,
      showTimers: props.show_timers,
      title: props.title,
      columns: props.columns.length > 0 ? props.columns : this.buildDefaultColumns(),
      useDefaultTitle: props.use_default_title,
    };
    window.OF.forceUpdatePresentationEditor = this.forceUpdateBodies;
  }
  componentDidUpdate(_, prevState) {
    if(prevState.columns !== this.state.columns) {
      $('.dirtyform').dirtyForms('setDirty');
      $('.tooltip').remove();
      this.setShowPresetSlides();
    }
    if(prevState.showPresetSlides !== this.state.showPresetSlides) {
      this.setPresetSlides();
    }
  }
  setShowPresetSlides() {
    const { columns } = this.state;
    this.setState({ showPresetSlides: columns.filter(_ => _.isPreset).some(_ => !_.hidden) });
  }
  setPresetSlides() {
    const { showPresetSlides, columns } = this.state;
    if (showPresetSlides) {
      if(columns.filter(_ => _.isPreset).every(_ => _.hidden)) {
        this.setState({ columns: columns.map(_ => _.isPreset ? ({ ..._, hidden: false }) : _) });
      }
    } else {
        this.setState({ columns: columns.map(_ => _.isPreset ? ({ ..._, hidden: true }) : _) });
    }
  }
  generateKey(columns) {
    const key = Math.random().toString(36).slice(-8);
    return columns.map(_ => _.key).includes(key) ? this.generateKey(columns) : key;
  }
  addColumn() {
    const { columns } = this.state;
    this.setState({
      columns: [...this.state.columns, { isPreset: false, key: this.generateKey(columns), body: '', hidden: false }],
    });
  }
  buildDefaultColumns() {
    return [
      ...['mission', 'schedule'].map(_ => ({ isBasic: true, key: _ })),
      ...['future_session', 'future_thinking', 'ground_rules', 'request'].map(_ => ({ isPreset: true, key: _ })),
    ].map(_ => ({ ..._, hidden: false }));
  }
  removeColumn = (index) => {
    const { columns } = this.state;
    return () => {
      this.setState({
        columns: [...columns.slice(0, index), ...columns.slice(index + 1)],
      });
    };
  }
  updateColumn = (index) => {
    const { columns } = this.state;
    return (key, value) => {
      this.setState({
        columns: [
          ...columns.slice(0, index),
          { ...columns[index], [key]: value },
          ...columns.slice(index + 1)
        ]
      });
    };
  }
  moveUpColumn = (index) => {
    const { columns } = this.state;
    return () => {
      this.setState({ columns: arrayMove(columns, index, index - 1) });
    };
  }
  moveDownColumn = (index) => {
    const { columns } = this.state;
    return () => {
      this.setState({ columns: arrayMove(columns, index, index + 1) });
    };
  }
  sortColumns = ({ oldIndex, newIndex }) => {
    this.setState({
      columns: arrayMove(this.state.columns, oldIndex, newIndex)
    });
  }
  forceUpdateBodies = () => {
    const { columns } = this.state;
    this.setState({
      columns: columns.map((column, i) => {
        const presentationEditorColumn = presentationEditorColumnRefs[i];
        const body = ((presentationEditorColumn || {}).textarea || {}).value;
        return { ...column, body };
      })
    });
  }
  onChoose = (name, value) => {
    this.setState({ [name]: value });
  }
  onCheck = (name, { target: { checked } }) => {
    this.setState({ [name]: checked });
  }
  onChangeText = (name, { target: { value } }) => {
    this.setState({ [name]: value });
  }
  render() {
    const { image_post_path: imagePostPath, SLIDE_THEMES, SLIDE_TRANSITIONS, mission, program_times: programTimes, steps } = this.props;
    const { theme, transition, showPresetSlides, showTimers, title, columns, useDefaultTitle } = this.state;
    return (
      <div className="presentation-editor">
        <input type="hidden" name="session[slide_theme]" value={theme} />
        <input type="hidden" name="session[slide_transition]" value={transition} />
        <input type="hidden" name="session[slide_show_preset_slides]" value={showPresetSlides ? '1' : '0'} />
        <input type="hidden" name="session[slide_show_timers]" value={showTimers ? '1' : '0'} />
        <input type="hidden" name="session[slide_title]" value={title} />
        <input type="hidden" name="session[slide_intro_columns]" value={JSON.stringify(columns)} />
        <input type="hidden" name="session[slide_use_default_title]" value={useDefaultTitle ? '1' : '0'} />
        <div className="form-group mb30">
          <div>
            <label className="control-label vertical-form-label">テーマカラー</label>
          </div>
          {
            SLIDE_THEMES.map((slideTheme) => {
              return (
                <span key={slideTheme} className={classnames('color-sample-box', `base-color-${slideTheme}`, 'cursor-pointer', { 'color-sample-box-selected': slideTheme === theme })} onClick={this.onChoose.bind(this, 'theme', slideTheme)}>
                </span>
              );
            })
          }
        </div>
        <div className="form-group mb30">
          <div>
            <label className="control-label vertical-form-label">アニメーション</label>
          </div>
          <div className="animation-sample-box">
            <ul>
              {
                entries(SLIDE_TRANSITIONS).map(([slideTransition, text]) => {
                  return (
                    <li key={slideTransition} className={classnames('cursor-pointer', { 'animation-sample-box-selected': slideTransition === transition })} onClick={this.onChoose.bind(this, 'transition', slideTransition)}>
                      {text}
                    </li>
                  );
                })
              }
            </ul>
          </div>
        </div>
        <div className="form-group mb30">
          <div className="checkbox">
            <label htmlFor="session_slide_show_preset_slides">
              <input type="checkbox" id="session_slide_show_preset_slides" checked={showPresetSlides} onChange={this.onCheck.bind(this, 'showPresetSlides')} />
              プリセットされたスライドの表示
            </label>
          </div>
          <div className="checkbox">
            <label htmlFor="session_slide_show_timers">
              <input type="checkbox" id="session_slide_show_timers" checked={showTimers} onChange={this.onCheck.bind(this, 'showTimers')} />
              カウントダウンタイマーの表示
            </label>
          </div>
        </div>
        <div className="form-group mb30">
          <div>
            <label className="control-label vertical-form-label">タイトル</label>
          </div>
          <div className="checkbox">
            <label htmlFor="session_slide_use_default_title">
              <input type="checkbox" id="session_slide_use_default_title" checked={useDefaultTitle} onChange={this.onCheck.bind(this, 'useDefaultTitle')} />
              セッション名をスライドのタイトルにする
            </label>
          </div>
          <div className="row">
            <div className="col-sm-7 mb15">
              <textarea rows={7} className="form-control" value={title} onChange={this.onChangeText.bind(this, 'title')} disabled={useDefaultTitle}>テストタイトル</textarea>
            </div>
            <div className="col-sm-5">
              <div className="slide-title-description-image" />
              <div className="help-block">
                赤枠の箇所に表示されます。
              </div>
            </div>
          </div>
        </div>
        <div className="form-group mb30">
          <div>
            <label className="control-label vertical-form-label">
              冒頭スライド
            </label>
          </div>
          <ColumnRows
            columns={columns}
            onChange={this.updateColumn}
            onClickUp={this.moveUpColumn}
            onClickDown={this.moveDownColumn}
            onClickRemove={this.removeColumn}
            onSortEnd={this.sortColumns}
            shouldCancelStart={
              ({ target }) => {
                const { tagName, classList } = target;
                const disabledElements = ['input', 'textarea', 'select', 'option', 'button', 'i', 'label'];
                return (
                  disabledElements.includes(tagName.toLowerCase()) || classList.contains('btn') || [target, ...parentNodes(target)].filter(_ => _.classList).some(_ => _.classList.contains('note-editor'))
                );
              }
            }
            imagePostPath={imagePostPath}
            mission={mission}
            programTimes={programTimes}
            steps={steps}
          />
        </div>
        <div className="row">
          <div className="col-sm-8 col-sm-offset-2">
            <button type="button" className="btn btn-default btn-block btn-lg" onClick={this.addColumn.bind(this)}><i className="fa fa-fw fa-plus"></i>項目追加</button>
          </div>
        </div>
      </div>
    );
  }
}
